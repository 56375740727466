import { Button } from 'common/components/Button/Button'
import Image, { StaticImageData } from 'next/image'
import Link from 'next/link'
import { FC } from 'react'
import styled from 'styled-components'

interface IIndividualsFeatures {
  featuresList: { title: string; description: string; image: StaticImageData; button?: string; redirect?: string }[]
  redirectIfAuthenticated?: (urlYes: string, urlNo: string) => void
}

const IndividualsFeatures: FC<IIndividualsFeatures> = ({ featuresList, redirectIfAuthenticated }) => {
  return (
    <Div className="flex flex-column gap-5">
      {featuresList?.map(({ title, description, image, button, redirect }, i) => (
        <div className="app-feature grid grid-nogutter gap-3 md:gap-0 py-1 md:py-3" key={i}>
          <div className="col-12 md:col-6 flex flex-column align-items-start justify-content-center md:px-5 gap-3">
            <h2>{title}</h2>
            <p>{description}</p>
            {button &&
              (redirect?.includes('play.google.com') ? (
                <Link href="https://play.google.com/store/apps/details?id=com.seaplify" passHref legacyBehavior>
                  <a target="_blank">
                    <Button size="large">{button}</Button>
                  </a>
                </Link>
              ) : (
                <Button
                  size="large"
                  onClick={() => redirectIfAuthenticated && redirect && redirectIfAuthenticated(redirect, '/sign-up')}
                >
                  {button}
                </Button>
              ))}
          </div>
          <div className="app-feature-image p-3 col-12 md:col-6 relative">
            <Image
              src={image}
              alt={title}
              className="max-w-full h-auto"
              sizes="100vw"
              style={{ objectFit: 'contain', objectPosition: 'center' }}
            />
          </div>
        </div>
      ))}
    </Div>
  )
}

export default IndividualsFeatures

const Div = styled.div`
  max-width: 1120px;
  margin: 60px auto;
  padding-inline: 1.5rem;
  text-align: left;

  .app-feature {
    color: #000000;

    h2 {
      font-weight: 700;
      font-size: clamp(20px, 5vw, 34px);
      max-width: 387px;
    }

    p {
      font-size: clamp(20px, 4vw, 24px);
      line-height: 150%;
      width: clamp(250px, 100%, 700px);
    }

    .app-feature-image {
      display: grid;
      place-items: center;

      img {
        height: clamp(250px, 90vw, 623px);
      }
    }
  }

  button {
    width: 211px;
    height: 44px;
  }

  @media screen and (min-width: 768px) {
    .app-feature {
      &:nth-child(odd) {
        flex-direction: row-reverse !important;
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin: 30px auto 10px;
  }
`
